"use strict";

(() => {
    $(window).on("scroll load", function () {
        $(".animation").each(function () {
            const elementPosition = this.offsetTop;
            const scrollPosition = window.scrollY + (window.innerHeight / 1.2);
            if (scrollPosition > elementPosition) {
                if (this.children[0].classList.contains("reveal")) {
                    $(this).children().each(function (index) {
                        setTimeout(() => {
                            this.classList.add("show");
                        }, 200 + 200 * index);
                    });
                } else {
                    this.classList.add("show");
                }
            }
        });
    });
})();
