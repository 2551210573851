"use strict";

(() => {
    $(".view-image").on("click", function () {
        let mq = window.matchMedia("(min-width: 992px)");
        if (mq.matches) {
            $("#image-preview").attr("src", this.querySelector("[src]").getAttribute("src"));

            $(".image-modal").css("display", "block");

            $(".btn-close").on("click", function () {
                $(".image-modal").css("display", "none");
            });

            $(document).on("click", function (e) {
                if ($(e.target).is(".image-modal")) {
                    $(".image-modal").fadeOut(400);
                }
            });
        }
    });
})();
