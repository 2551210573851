"use strict";

(() => {
    $("#nav-search").on("submit", function (e: Event) {
        search(e);
    });

    $("#tag-search").on("submit", function (e: Event) {
        search(e);
    });

    $("#footer-contact").on("submit", function (e: Event) {
        if (checkForm(this, e) == true) footerContact(e);
    });

    $("#apply-form").on("submit", function (e: Event) {
        contact(e);
    });

    $("#contact-form").on("submit", function (e: Event) {
        if (checkForm(this, e) == true) contact(e);
    });

    $("#email-form").on("submit", function (e: Event) {
        if (checkForm(this, e) == true) email(e);
    });

    $("#res-form").on("submit", function (e: Event) {
        if (checkForm(this, e) == true) reserve(e);
    });

    $("#comment-form").on("submit", function (e: Event) {
        if (checkForm(this, e) == true) comment(e);
    });

    function comment(e: Event) {
        e.preventDefault();
        $("#comment-alert").removeClass("d-none");
        const $comment = $("#comment input, #comment textarea");
        $comment.val("");
        $comment.removeClass("valid");
        $(this).removeClass("valid");
    }

    function email(e: Event) {
        e.preventDefault();
        $("#email-alert").removeClass("d-none");
        const $input = $("#email-form input");
        $input.val("");
        $input.removeClass("valid");
        window.location.replace(window.location.href.split(/[#]/)[0] + "#stay-email");
        $(this).removeClass("valid");
    }

    function search(e: Event) {
        e.preventDefault();
        window.location.replace("./?page=search_results");
    }

    function footerContact(e: Event) {
        e.preventDefault();
        $("#footer-alert").removeClass("d-none");
        const $input = $("#footer input, #footer textarea");
        $input.val("");
        $input.removeClass("valid");
        window.location.replace(window.location.href.split(/[#]/)[0] + "#footer");
        $(this).removeClass("valid");
    }

    function contact(e: Event) {
        e.preventDefault();
        $("#contact-alert").removeClass("d-none");
        const $input = $("#contact-form input, #contact-form textarea");
        $input.val("");
        $input.removeClass("valid");
        window.location.replace(window.location.href.split(/[#]/)[0] + "#contact-form");
        $(this).removeClass("valid");
    }

    function reserve(e: Event) {
        e.preventDefault();
        $("#reservation-alert").removeClass("d-none");
        const $input = $("#res-form input, #res-form textarea");
        $input.val("");
        $input.removeClass("valid");
        window.location.replace(window.location.href.split(/[#]/)[0] + "#reservation-content");
        $(this).removeClass("valid");
    }

    function checkForm(form: HTMLElement, e: Event): boolean {
        let ret = true;
        $(form).find("input, textarea").each(function () {
            $(this).removeClass("valid invalid");
            $(this).parent().children(".error-message").remove();

            if ($(this).prop("required")) {
                if ($(this).val().toString().length == 0) {
                    e.preventDefault();
                    $(this).addClass("invalid");
                    $(this).parent().append("<p class=\"error-message\">This field is required</p>");
                    ret = false;
                } else {
                    $(this).addClass("valid");
                }
            }
            if ($(this).attr("type") == "email" && $(this).val().toString().length != 0 && $(this).is(":invalid")) {
                e.preventDefault();
                $(this).removeClass("valid");
                $(this).addClass("invalid");
                $(this).parent().append("<p class=\"error-message\">Please enter a valid e-mail address</p>");
                ret = false;
            }
        });
        return ret;
    }
})();
