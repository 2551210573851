"use strict";

((): void => {
    (<any>window).Arcas = (<any>window).Arcas || {};

    (<any>window).Arcas.Utils = class {
        static isMinWidth(width: number): boolean {
            return window.matchMedia(`(min-width: ${width}px)`).matches;
        }

        static isMobileDevice(): boolean {
            const toMatch: RegExp[] = [
                /Android/i,
                /iP(ad|od|hone)/i,
            ];

            return toMatch.some((toMatchItem: RegExp) => {
                return navigator.userAgent.match(toMatchItem);
            });
        }

        static isTouchDevice(): boolean {
            return navigator.maxTouchPoints > 0;
        }
    }
})();
