"use strict";

(() => {
    $("a[href*=\"top-items\"]").on("click", function (event) {
        event.preventDefault();
        $("html,body").animate({scrollTop: $("#top-items").offset().top}, 400);
    });

    $("a[href=\"#top\"]").on("click", function (event) {
        event.preventDefault();
        $("html,body").animate({scrollTop: 0}, 600);
    });

    $(window).on("load resize", function () {
        const $heroScrollDown = $(".hero-scroll-down");

        if ($heroScrollDown.length !== 0) {
            const $herCallToAction = $(".hero-call-to-action");

            if ($heroScrollDown.offset().top <= ($herCallToAction.offset().top + $herCallToAction.height() + 10)) {
                $heroScrollDown.css("opacity", "0");
            } else {
                $heroScrollDown.css("opacity", "1");
            }
        }
    });
})();
