"use strict";

(() => {
    let faqCategories = document.querySelectorAll(".faq-category");
    faqCategories.forEach((faqCategory) => {
        faqCategory.addEventListener("click", (event) => {
            event.preventDefault();

            faqCategories.forEach(function (faqCategory) {
                faqCategory.classList.remove("active");
            });

            faqCategory.classList.add("active");
        });
    });

    let faqHeaders = document.querySelectorAll(".faq-header");
    faqHeaders.forEach((faqHeader) => {
        let faqTitle = faqHeader.querySelector(".faq-title");
        let faqAnswer = faqHeader.closest(".faq-question").querySelector(".faq-answer");
        let faqControl = faqHeader.querySelector(".faq-control");
        faqHeader.addEventListener("click", () => {
            if (faqAnswer.classList.contains("hidden")) {
                faqTitle.classList.add("active");
                faqControl.classList.add("active");
                faqAnswer.classList.remove("hidden");
                faqControl.textContent = "Hide";
            } else {
                faqTitle.classList.remove("active");
                faqControl.classList.remove("active");
                faqAnswer.classList.add("hidden");
                faqControl.textContent = "Show";
            }
        });
    });
})();
