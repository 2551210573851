"use strict";

(() => {
    document.addEventListener("DOMContentLoaded", () => {
        const lazyBackgroundElements = Array.from(document.querySelectorAll("[data-background-image]"));

        if (lazyBackgroundElements.length === 0) {
            return;
        }

        let lazyBackgroundObserver: IntersectionObserver;

        const setBackground = (element: HTMLElement) => {
            let imageUrl = element.dataset.backgroundImage;
            element.style.setProperty("backgroundImage", `url('${imageUrl}')`);
        };

        if (typeof window.IntersectionObserver === "function") {
            lazyBackgroundObserver = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // @ts-ignore
                        setBackground(entry.target);
                        lazyBackgroundObserver.unobserve(entry.target);
                    }
                });
            }, {
                rootMargin: "125%"
            });
            lazyBackgroundElements.forEach((element) => {
                lazyBackgroundObserver.observe(element);
            });
        } else {
            lazyBackgroundElements.forEach(setBackground);
        }
    });
})();
