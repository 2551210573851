"use strict";

((): void => {
    window.addEventListener("load", (): void => {
        $(".loader").fadeOut(500, function (): void {
            $(this).remove();
        });

        document.querySelectorAll(".hero-heading")?.forEach((element: HTMLElement): void => {
            element.classList.add("show");
        });
        document.querySelectorAll(".hero-subheading")?.forEach((element: HTMLElement): void => {
            element.classList.add("show");
        });
        document.querySelectorAll(".hero-text")?.forEach((element: HTMLElement): void => {
            element.classList.add("show");
        });
        document.querySelectorAll(".hero-call-to-action")?.forEach((element: HTMLElement): void => {
            element.classList.add("show");
        });
    });
})();
