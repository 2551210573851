"use strict";

(() => {
    let infoNumbers: boolean = false;

    $(window)
        .on("load scroll", function () {
            countInfoNumbers();
        });

    function countInfoNumbers() {
        const $info = $(".info-number");
        if ($info.length != 0 && !infoNumbers) {
            let specialization_pos = $info.offset().top;
            let scroll_pos = window.scrollY + window.innerHeight;

            if (scroll_pos > specialization_pos) {
                $info.each(function () {
                    let count = this.getAttribute("data-value");
                    let add = $(this).find("div.info-heading").text();
                    let duration = 2500;
                    if (parseInt(count) <= 10) {
                        duration = 1500;
                    }

                    $(this).prop("Counter", 0).animate({Counter: count}, {
                        duration: duration,
                        easing: "swing",
                        step: function (number) {
                            $(this).find("div.info-heading").text(Math.ceil(number) + add);
                        }
                    });
                    infoNumbers = true;
                })
            }
        }
    }
})();
