"use strict";

(() => {
    $(window).on("load scroll", function () {
        const $footerScrollUp = $(".footer-scroll-up");
        if ($footerScrollUp.length != 0) {
            let footer_pos = $footerScrollUp.offset().top;
            let scroll_pos = window.scrollY + (window.innerHeight / 1.15);

            const $footerScrollUpA = $(".footer-scroll-up a");
            if (scroll_pos > footer_pos) {
                if ($footerScrollUp.length != 0) {
                    $footerScrollUpA.addClass("jumpOnScroll");
                }
            } else if (scroll_pos < footer_pos) {
                if ($footerScrollUp.length != 0) {
                    $footerScrollUpA.removeClass("jumpOnScroll");
                }
            }
        }
    })

    $(".current-year").html(new Date().getFullYear().toString());
})();
